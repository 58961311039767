import { camelCase, get } from 'lodash';
import React from 'react';

import { FormControl, Grid, MenuItem, TextField } from '@mui/material';

const ReferralOptionsComp = ({
  referralOptions,
  values,
  fieldKey,
  fullWidth,
  touched,
  required,
  label,
  errors,
  handleChange,
  handleBlur,
}) => {
  const howDidYouHearAboutUsOptions = referralOptions.map((optionObj) => {
    const { optionName, showOption } = optionObj;
    const id = camelCase(optionName);
    return { value: optionName, id, showOption };
  });
  const key = fieldKey;

  const getSelectedValue = () => {
    const value = get(values, key);

    return value || '';
  };
  return (
    <Grid
      key={`${key}-container`}
      item
      xs={12}
      md={!fullWidth ? 6 : 12}
      sx={{ textAlign: 'left' }}
    >
      <FormControl fullWidth>
        <TextField
          label={required ? `${label} *` : label}
          key={key}
          id={key}
          error={!!(touched[key] && errors[key])}
          helperText={touched[key] && errors[key]}
          select
          variant="outlined"
          value={getSelectedValue()}
          onChange={(e) => {
            const { value } = e.target;
            handleChange({
              target: { id: key, value },
            });

            const foundOptionObj = howDidYouHearAboutUsOptions.find(
              (optionObj) => optionObj.id === value
            );
            handleChange({
              target: {
                id: 'regularOptionValue',
                value: foundOptionObj.value,
              },
            });
          }}
        >
          {howDidYouHearAboutUsOptions.map((option) => {
            const { id, value } = option;
            return (
              <MenuItem id={id} key={id} value={id}>
                {value}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
      {values.howDidYouHearAboutUs === 'other' && (
        <TextField
          style={{ marginTop: 6 }}
          id="referralText"
          variant="outlined"
          label="How did you about us information"
          placeholder="How did you about us information"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!(touched['referralText'] && errors['referralText'])}
          helperText={touched['referralText'] && errors['referralText']}
          multiline
          rows={3}
          fullWidth
        />
      )}
      {values.howDidYouHearAboutUs === 'wordOfMouth' && (
        <TextField
          style={{ marginTop: 6 }}
          id="recommender"
          variant="outlined"
          label="Who recommended us?"
          placeholder="Who recommended us?"
          onChange={handleChange}
          error={!!(touched['recommender'] && errors['recommender'])}
          helperText={touched['recommender'] && errors['recommender']}
          multiline
          rows={3}
          fullWidth
        />
      )}
    </Grid>
  );
};

export default ReferralOptionsComp;
