import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { Box } from '@mui/material';

const VideoPlayer = ({
  videoID,
  marginTop = 15,
  controls = true,
  loop = false,
}) => {
  const url = `https://vimeo.com/${videoID}`;

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          pt: '56.25%',
          mt: marginTop,
        }}
      >
        <ReactPlayer
          id="MyId"
          width="100%"
          height="100%"
          style={{
            position: 'absolute',
            overflow: 'hidden',
            top: 0,
            left: 0,
          }}
          controls={controls}
          playing
          muted
          loop={loop}
          url={url}
        ></ReactPlayer>
      </Box>
    </Box>
  );
};

export default VideoPlayer;
