export const getBackgroundImageGatsbyData = ({ imageName, sanityData }) => {
  const { sanityCommonContent } = sanityData;
  const { backgrounds } = sanityCommonContent;
  const foundBackground = backgrounds.find((background) => {
    const { altText } = background;
    return altText === imageName;
  });
  const { asset } = foundBackground;
  const { gatsbyImageData } = asset;
  return gatsbyImageData;
};
