import React, { useContext } from 'react';

import { OndemandVideo } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import ContactButton from '../../button/ContactButton';
import DemoButton from '../../button/DemoButton';
import PlanYourEventButton from '../../button/PlanYourEventButton';
import ScrollButton from '../../button/ScrollButton';
import Logo from '../../logo/Logo';
import styles from '../../styling/styles';
import LanguagePopover from '../language/LanguagePopover';
import DrawerNavItems from './DrawerNavItems';

const logoStyle = {
  filter:
    'invert(88%) sepia(43%) saturate(5794%) hue-rotate(235deg) brightness(78%) contrast(99%)',
  pb: 1,
};

const FangoDrawer = () => {
  const { navButtons, pathname, homeDirectories } = useContext(DataContext);

  const isHomepage = homeDirectories.includes(pathname);
  const isContactPage = pathname.includes('contactus');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ paddingY: 1, flex: 1 }}>
        <Box sx={logoStyle}>
          <Logo width={70} center />
        </Box>
        <Divider />
        <DrawerNavItems />
      </Box>

      <Box sx={{ textAlign: 'center', my: 5 }}>
        <Box sx={{ my: 4 }}>
          <LanguagePopover mobile center fontColor={styles.grey} />
        </Box>

        {(isHomepage || isContactPage) && (
          <ScrollButton
            buttonName={navButtons.contact}
            fontSize={13}
            buttonWidth="80%"
          />
        )}
        {!isHomepage && !isContactPage && (
          <ContactButton
            buttonName={navButtons.contact}
            fontSize={13}
            buttonWidth="80%"
          />
        )}

        <Box sx={{ textAlign: 'center', my: 3 }}>
          <DemoButton
            buttonName={navButtons.demo}
            variant="outlined"
            startIcon={<OndemandVideo />}
            buttonWidth="80%"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FangoDrawer;
