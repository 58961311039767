// Theme Colors
const white = 'common.white';
const black = 'common.black';
const grey = 'grey.900';
const lightGrey = 'grey.light';
const darkBlue = 'primary.main';
const blogBlue = 'common.blue';
const lightBlue = 'primary.light';
const deepGreen = 'secondary.main';
const lightGreen = 'secondary.light';

// Almost white background - used for the background surrounding gif images on the Christmas page.
const offWhite = 'common.offWhite';

// Christmas Gold
const gold = 'common.gold';
// Grid Settings
const displayStyleBBGG = {
  xs: 'block',
  md: 'grid',
};

const displayStyleFFGG = {
  xs: 'flex',
  md: 'grid',
};

const boxShadowStyles = '3px 3px 10px 1px grey';

// Form Styles
const labelStyle = {
  sx: {
    color: '#919EAB',
  },
};

const inputStyle = {
  disableUnderline: true,
  sx: {
    borderRadius: '4px',
  },
};

const displayStyleBBBG = { xs: 'block', lg: 'grid' };

const displayStyleBBNN = { xs: 'block', md: 'none' };

const displayStyleNNBB = { xs: 'none', md: 'block' };

const bulletPointBodyStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 15fr',
};

const gridTemplateMD2LG4 = {
  md: '2fr 2fr',
  lg: '2fr 2fr 2fr 2fr',
};

// Social Icons
const socialIconsStyle = {
  display: 'grid',
  height: 30,
  width: 30,
  mr: 1,
  mt: 1,
  cursor: 'pointer',
  backgroundColor: blogBlue,
  alignItems: 'center',
  justifyItems: 'center',
  lineHeight: 40,
  borderRadius: '50%',
  color: white,

  transition: 'all 0.5s ease',
  ':hover': {
    backgroundColor: deepGreen,
    color: white,
  },
};

// Logo Style
const logoBodyStyle = {
  height: '160px',
  width: '160px',
  mb: 2,
  backgroundColor: 'white',
  borderRadius: 5,
  boxShadow: '0 3px 10px rgb(140, 152, 164, 0.3)',
  px: 2,
  py: 5,
  display: 'inline-block',
  mx: '10px',
  marginTop: '-30px',
  marginBottom: '70px',
  transition: 'all .2s ease-out',
  ':hover': {
    opacity: 0.5,
    cursor: 'pointer',
  },
};

// Padding & Margin
const allTwenty = {
  xs: 20,
  sm: 20,
  md: 20,
  lg: 20,
};

const allFifteen = {
  xs: 15,
  sm: 15,
  md: 15,
  lg: 15,
};

const allTen = {
  xs: 10,
  sm: 10,
  md: 10,
  lg: 10,
};

const allSix = {
  xs: 6,
  sm: 6,
  md: 6,
  lg: 6,
};

const allFive = {
  xs: 5,
  sm: 5,
  md: 5,
  lg: 5,
};

const allFour = {
  xs: 4,
  sm: 4,
  md: 4,
  lg: 4,
};

const allThree = {
  xs: 3,
  sm: 3,
  md: 3,
  lg: 3,
};

const allTwo = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 2,
};

const allOne = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const allZero = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
};
const allNegTen = {
  xs: -10,
  sm: -10,
  md: -10,
  lg: -10,
};

const allNeg1em = {
  xs: '-1em',
};

const allAuto = {
  xs: 'auto',
};

//   Align styles
const alignStyleCCCC = {
  xs: 'center',
};

const alignStyleLLLL = {
  xs: 'left',
};

const alignStyleCCCL = {
  xs: 'center',
  lg: 'left',
};

const alignStyleCCLL = {
  xs: 'center',
  md: 'left',
};

const alignStyleCLLL = {
  xs: 'center',
  sm: 'left',
};

const alignStyleCCJJ = {
  xs: 'center',
  md: 'justify',
};

const alignStyleLLCC = {
  xs: 'left',
  md: 'center',
};

const alignStyleLLJJ = {
  xs: 'left',
  md: 'justify',
};

const alignStyleJJJJ = {
  xs: 'justify',
};

export default {
  displayStyleBBGG,
  displayStyleNNBB,
  displayStyleBBNN,
  displayStyleBBBG,
  displayStyleFFGG,
  gridTemplateMD2LG4,
  bulletPointBodyStyle,
  socialIconsStyle,
  logoBodyStyle,
  labelStyle,
  inputStyle,
  allTwenty,
  allFifteen,
  allTen,
  allSix,
  allFive,
  allFour,
  allThree,
  allTwo,
  allOne,
  allZero,
  allNegTen,
  allNeg1em,
  allAuto,
  white,
  black,
  grey,
  gold,
  offWhite,
  lightGrey,
  darkBlue,
  blogBlue,
  lightBlue,
  deepGreen,
  lightGreen,
  alignStyleCCCC,
  alignStyleLLLL,
  alignStyleCCCL,
  alignStyleCLLL,
  alignStyleCCLL,
  alignStyleLLCC,
  alignStyleLLJJ,
  alignStyleCCJJ,
  alignStyleJJJJ,
  boxShadowStyles,
};
