import React, { useContext } from 'react';

import { Box } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import { FanGoMenuItem } from '../menuItem';

const NavBar = () => {
  const { navLinks } = useContext(DataContext);
  return (
    <Box sx={{ display: 'flex' }}>
      {navLinks.map((navLink) => {
        if (navLink.key === 'company') {
          return;
        }

        return <FanGoMenuItem key={navLink.key} navLink={navLink} />;
      })}
    </Box>
  );
};

export default NavBar;
