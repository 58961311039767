import { Typography } from '@mui/material';
import React from 'react';

const FooterSubText = ({
  value,
  linked = true,
  onClick = () => {},
  fontWeight = undefined,
}) => {
  const textStyle = {
    color: 'common.white',
    transition: 'all 0.3s ease',
    ':hover': linked
      ? {
          color: 'secondary.main',
          pl: 1,
        }
      : undefined,
  };
  return (
    <Typography
      variant="subtitle2"
      sx={textStyle}
      children={value}
      onClick={onClick}
      fontWeight={fontWeight}
    />
  );
};

export default FooterSubText;
