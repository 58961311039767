import axios from 'axios';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import * as yup from 'yup';

import { Alert, Snackbar } from '@mui/material';

import { DataContext } from '../../providers/DataProvider';
import DemoForm from './DemoForm';

const errorShape = {
  name: yup.string().trim().required('Name is required'),
  workEmail: yup
    .string()
    .trim()
    .email('Invalid email')
    .required('Work Email is required'),
  productOfInterest: yup.array().min(1, 'Product(s) of Interest is required'),
  jobTitle: yup.string().trim().required('Job Title is required'),
  company: yup.string().trim().required('Company is required'),
  howDidYouHearAboutUs: yup
    .string()
    .trim()
    .required('How you heard about us is required'),
  referralText: yup.string().when(['howDidYouHearAboutUs'], {
    is: (howDidYouHearAboutUs) => howDidYouHearAboutUs === 'other',
    then: (schema) => schema.required('Other information is required'),
  }),
  recommender: yup.string().when(['howDidYouHearAboutUs'], {
    is: (howDidYouHearAboutUs) => howDidYouHearAboutUs === 'wordOfMouth',
    then: (schema) =>
      schema.required('Information regarding who recommended us is required'),
  }),
};

const initialValues = {
  name: '',
  workEmail: '',
  phone: '',
  company: '',
  jobTitle: '',
  aboutCompany: '',
  productOfInterest: [],
  other: '',
  howDidYouHearAboutUs: '',
  referralText: '',
  recommender: '',
  regularOptionValue: '',
};

const DemoFormHandler = ({ type = 'demo', formData }) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setShowDemoDialog } = useContext(DataContext);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setError('');
  };

  const onSubmit = async (values) => {
    let regularSelected = false;
    let otherSelected = false;
    let wordOfMouthSelected = false;
    if (values.howDidYouHearAboutUs === 'other') {
      otherSelected = true;
    } else if (values.howDidYouHearAboutUs === 'wordOfMouth') {
      wordOfMouthSelected = true;
    } else {
      regularSelected = true;
    }

    const formattedValues = {
      ...values,
      regularSelected,
      otherSelected,
      wordOfMouthSelected,
    };

    try {
      setError('');
      setIsLoading(true);
      await axios.post('/.netlify/functions/contactUs', {
        ...formattedValues,
        type,
      });
      navigate('/contactsuccess' + '/');
      setIsLoading(false);
      setShowDemoDialog(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={!isLoading ? onSubmit : null}
        validationSchema={yup.object().shape(errorShape)}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
        }) => (
          <DemoForm
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            formData={formData}
          />
        )}
      </Formik>

      {!!error && (
        <Snackbar
          open
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
            Your request could not be sent. Please try again.
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default DemoFormHandler;
