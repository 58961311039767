import React, { useContext } from 'react';

import { DataContext } from '../providers/DataProvider';

export const getLink = ({ key, pageContext = null }) => {
  if (!key) {
    return;
  }

  if (!pageContext) {
    let { pageContext: existingContext } = useContext(DataContext);

    pageContext = existingContext;
  }

  const { language } = pageContext;

  const link = key.toLowerCase();

  if (language === 'en') {
    if (link === 'home') {
      return '/';
    }

    return `/${link}/`;
  } else {
    if (link === 'home') {
      return `/${language}/`;
    }

    return `/${language}/${link}/`;
  }
};
