exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-auditorium-tsx": () => import("./../../../src/pages/auditorium.tsx" /* webpackChunkName: "component---src-pages-auditorium-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-christmas-tsx": () => import("./../../../src/pages/christmas.tsx" /* webpackChunkName: "component---src-pages-christmas-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contactsuccess-tsx": () => import("./../../../src/pages/contactsuccess.tsx" /* webpackChunkName: "component---src-pages-contactsuccess-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-cookiespolicy-tsx": () => import("./../../../src/pages/cookiespolicy.tsx" /* webpackChunkName: "component---src-pages-cookiespolicy-tsx" */),
  "component---src-pages-customertcs-tsx": () => import("./../../../src/pages/customertcs.tsx" /* webpackChunkName: "component---src-pages-customertcs-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-gettingstarted-tsx": () => import("./../../../src/pages/gettingstarted.tsx" /* webpackChunkName: "component---src-pages-gettingstarted-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrationsandpartners-tsx": () => import("./../../../src/pages/integrationsandpartners.tsx" /* webpackChunkName: "component---src-pages-integrationsandpartners-tsx" */),
  "component---src-pages-meetingrooms-tsx": () => import("./../../../src/pages/meetingrooms.tsx" /* webpackChunkName: "component---src-pages-meetingrooms-tsx" */),
  "component---src-pages-ourdevelopment-tsx": () => import("./../../../src/pages/ourdevelopment.tsx" /* webpackChunkName: "component---src-pages-ourdevelopment-tsx" */),
  "component---src-pages-planyourevent-tsx": () => import("./../../../src/pages/planyourevent.tsx" /* webpackChunkName: "component---src-pages-planyourevent-tsx" */),
  "component---src-pages-preordering-tsx": () => import("./../../../src/pages/preordering.tsx" /* webpackChunkName: "component---src-pages-preordering-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacynotice-tsx": () => import("./../../../src/pages/privacynotice.tsx" /* webpackChunkName: "component---src-pages-privacynotice-tsx" */),
  "component---src-pages-tableplans-tsx": () => import("./../../../src/pages/tableplans.tsx" /* webpackChunkName: "component---src-pages-tableplans-tsx" */),
  "component---src-pages-ticketing-tsx": () => import("./../../../src/pages/ticketing.tsx" /* webpackChunkName: "component---src-pages-ticketing-tsx" */),
  "component---src-pages-weddings-tsx": () => import("./../../../src/pages/weddings.tsx" /* webpackChunkName: "component---src-pages-weddings-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

