import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box } from "@mui/material";

const BodyBackground = ({ bgImage, bgColor, content, marginY = null }) => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        sanityCommonContent(language: { eq: "en" }) {
          _id
          backgrounds {
            altText
            asset {
              url
            }
          }
        }
      }
    `
  );

  const { sanityCommonContent } = data;

  const { backgrounds } = sanityCommonContent;

  let imageData = undefined;

  switch (bgImage) {
    case "middle":
      imageData = backgrounds.find(
        (background) => background.altText === "middle"
      );
      break;
    case "bottom":
      imageData = backgrounds.find(
        (background) => background.altText === "bottom"
      );
      break;
    case "wedding":
      imageData = backgrounds.find(
        (background) => background.altText === "wedding"
      );
      break;
    default:
      console.log("This case doesn't exist");
  }

  const bgStyle = {
    width: "100%",
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-y",
    backgroundSize: { xs: "cover", xl: "110% 100%" },
    backgroundColor: bgColor,
    backgroundImage: `url(${imageData?.asset?.url})`,
    my: marginY || undefined,
    py: { xl: 5 },
  };

  return <Box sx={bgStyle}>{content}</Box>;
};

export default BodyBackground;
