const customPalette = {
  primary: {
    main: '#141855',
    light: '#F7FAFF',
  },
  secondary: {
    main: '#2D9B83',
    light: '#d9fff9',
  },
  christmas: {
    main: '#CBA429',
    contrastText: '#fff',
  },
  common: {
    white: '#fff',
    blue: '#0D4379',
    black: '#000',
    offWhite: '#fdfdfd',
    gold: '#CBA429',
  },
  grey: {
    900: '#525252',
    light: 'rgb(249, 250, 251)',
  },
  neutral: {
    main: '#fff',
  },
};

export default customPalette;
