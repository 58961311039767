import React from 'react';

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { AppBar, Box } from '@mui/material';

import ToolBarContent from '../toolBar/ToolBarContent';

const toolBarContainerStyle = {
  top: 0,
  flexWrap: 'wrap',
  maxWidth: 1200,
  width: '100%',
  margin: 'auto',
};

const fadeIn = keyframes`
from{
  color: rgba(20, 24, 85, 0 )
} 

to{
  color: rgba(20, 24, 85, 255 )
}`;

const SparkleWrapper = styled.span`
  @media (prefers-reduced-motion: no-preference) {
    animation: ${fadeIn} 2000ms forwards;
  }
`;

const FanGoAppBar = ({ colored = false }) => {
  if (colored) {
    return (
      // <SparkleWrapper>

      // </SparkleWrapper>
      <AppBar color="primary" elevation={0} component="nav">
        <Box sx={toolBarContainerStyle}>
          <ToolBarContent />
        </Box>
      </AppBar>
    );
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 100,
        width: '100%',
      }}
    >
      <AppBar
        color="transparent"
        elevation={0}
        component="nav"
        position="static"
      >
        <Box sx={toolBarContainerStyle}>
          <ToolBarContent />
        </Box>
      </AppBar>
    </Box>
  );
};

export default FanGoAppBar;
