import { Box, Link } from '@mui/material';
import React from 'react';
import { TiSocialFacebook } from '@react-icons/all-files/ti/TiSocialFacebook';
import { TiSocialLinkedin } from '@react-icons/all-files/ti/TiSocialLinkedin';
import { TiSocialInstagram } from '@react-icons/all-files/ti/TiSocialInstagram';
import styles from '../../styling/styles';

const socials = [
  {
    link: 'https://www.facebook.com/FanGoCloud',
    icon: <TiSocialFacebook />,
  },
  {
    link: 'https://www.linkedin.com/company/fangocloud',
    icon: <TiSocialLinkedin />,
  },
  {
    link: 'https://www.instagram.com/fango_/',
    icon: <TiSocialInstagram />,
  },
];

const socialIconsContainerStyle = {
  display: 'flex',
  gap: '1.3rem',
  fontSize: '1.2rem',
};

const SocialIcons = () => {
  return (
    <Box sx={socialIconsContainerStyle}>
      {socials.map((social, index) => {
        return (
          <Link
            href={social.link}
            aria-label="FanGo-social-media-links"
            key={index}
            sx={styles.socialIconsStyle}
          >
            {social.icon}
          </Link>
        );
      })}
    </Box>
  );
};

export default SocialIcons;
