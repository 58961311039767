import React, { useContext } from 'react';

import { Button } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';
import { getLink } from '../../../utils/getLink';
import Sparkles from '../sparkleComp/Sparkles';

const ContactButton = ({
  buttonName,
  boxShadow = null,
  fontSize = 15,
  buttonWidth = null,
  variant = null,
  startIcon = null,
  color = null,
  lineHeight = null,
  minWidth = 'auto',
}) => {
  const { handleDrawerToggle, mobileOpen, pathname } = useContext(DataContext);

  const christmasPageButtonColor = pathname.includes('christmas')
    ? 'christmas'
    : null;

  const buttonStyle = {
    textTransform: 'initial !important',
    boxShadow: boxShadow || '0 3px 10px rgb(0 0 0 / 0.2)',
    fontSize: fontSize,
    width: buttonWidth || null,
    lineHeight: lineHeight,
    minWidth: minWidth,
    margin: 'auto',
    outline: 'none',
    display: 'block',
    cursor: 'pointer',
    // animationName: 'ripple',
    // WebkitAnimationName: 'ripple',
    // animationDuration: '1.5s',
    // WebkitAnimationDuration: '1.5s',
    // animationIterationCount: 'infinite',
    // WebkitAnimationIterationCount: 'infinite',
    // '@keyframes ripple': {
    //   '0%': {
    //     boxShadow: '0 0 0 0 #fff4, 0 0 0 0 #fff4',
    //   },
    //   '80%': {
    //     boxShadow: '0 0 0 8px #fff0, 0 0 0 16px #fff0',
    //   },
    //   '100%': {
    //     boxShadow: '0 0 0 0 #fff0, 0 0 0 0 #fff0',
    //   },
    // },
  };

  const handleButtonClick = () => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <Button
      href={getLink({ key: 'contactus' })}
      variant={variant || 'contained'}
      color={christmasPageButtonColor || color || 'secondary'}
      size="large"
      sx={buttonStyle}
      startIcon={startIcon || undefined}
      onClick={handleButtonClick}
    >
      {buttonName}
    </Button>
  );
};

export default ContactButton;
