import { Typography } from '@mui/material';
import React from 'react';
import styles from '../styling/styles';

const MainText = ({
  fontColor = styles.white,
  fontSize = null,
  textAlignment = styles.alignStyleCCCC,
  marginY = styles.allZero,
  paddingX = styles.allZero,
  paddingY = styles.allZero,
  paddingTop = null,
  paddingBottom = null,
  marginBottom = styles.allZero,
  marginBlockStart = null,
  marginBlockEnd = null,
  backgroundColor = null,
  fontWeight = null,
  textVariant,
  strikeThrough = false,
  value,
}) => {
  return (
    <Typography
      variant={textVariant}
      sx={{
        color: fontColor,
        mb: marginBottom,
        my: marginY,
        py: paddingY,
        px: paddingX,
        fontWeight: fontWeight || undefined,
        pb: paddingBottom || null,
        textAlign: textAlignment,
        pt: paddingTop || undefined,
        fontSize: fontSize || undefined,
        marginBlockStart: marginBlockStart || undefined,
        marginBlockEnd: marginBlockEnd || undefined,
        backgroundColor: backgroundColor || undefined,
        textDecoration: strikeThrough && 'line-through',
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
      }}
      children={value}
    />
  );
};

export default MainText;
