import React, { useContext } from "react";
import { Box, Link } from "@mui/material";
import { FooterMainText, FooterSubText } from "../../texts";
import { DataContext } from "../../../../providers/DataProvider";
import { getLink } from "../../../../utils/getLink";

const containerStyle = { mb: 2 };

const QuickLinks = () => {
  const { navLinks, navGroupName } = useContext(DataContext);

  return (
    <Box>
      <FooterMainText value={navGroupName} />
      <Box>
        {navLinks.map((navLink, index) => {
          if (navLink.key === "company") {
            return;
          }

          let keyToUse = navLink.key;

          if (navLink.navSubLinks) {
            keyToUse = navLink.navSubLinks[0].key;
          }

          return (
            <Box key={index} sx={containerStyle}>
              <Link href={getLink({ key: keyToUse })} underline="none">
                <FooterSubText value={navLink.label} />
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default QuickLinks;
