import LoadingButton from "@mui/lab/LoadingButton";
import PublishIcon from "@mui/icons-material/Publish";
import React from "react";

const buttonStyle = {
  textTransform: "initial !important",
  boxShadow: 3,
  fontSize: 20,
};

const FormButton = ({
  handleSubmit,
  isLoading,
  width = "100%",
  buttonName = "Submit",
}) => {
  return (
    <LoadingButton
      size="large"
      color="secondary"
      onClick={handleSubmit}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<PublishIcon />}
      variant="contained"
      sx={{ ...buttonStyle, width }}
    >
      <span>{buttonName}</span>
    </LoadingButton>
  );
};

export default FormButton;
