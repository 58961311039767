import React, { useContext } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import OndemandVideo from '@mui/icons-material/OndemandVideo';
import { Box, IconButton, Stack } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import ContactButton from '../../button/ContactButton';
import DemoButton from '../../button/DemoButton';
import PlanYourEventButton from '../../button/PlanYourEventButton';
import ScrollButton from '../../button/ScrollButton';
import AdditionalButtonsBar from './AdditionalButtonsBar';

const buttonAndMenuIconContainerStyle = {
  display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' },
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  my: 1.2,
  marginLeft: '8px',
};

const menuIconButtonStyle = { display: 'flex', color: 'common.white', mr: 1 };

const SmallScreenConfig = () => {
  const { handleDrawerToggle, navButtons, pathname, homeDirectories } =
    useContext(DataContext);

  const isHomepage = homeDirectories.includes(pathname);
  const isContactPage = pathname.includes('contactus');

  return (
    <Stack direction="row" sx={buttonAndMenuIconContainerStyle}>
      <>
        <AdditionalButtonsBar />
        <IconButton
          size="large"
          aria-label="meu-icon"
          aria-haspopup="true"
          onClick={handleDrawerToggle}
          sx={menuIconButtonStyle}
        >
          <MenuIcon />
        </IconButton>
      </>
    </Stack>
  );
};

export default SmallScreenConfig;
