import React, { useContext, useState } from 'react';

import { List, ListItem } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import FanGoIconGenerator from '../../icon/FanGoIconGenerator';
import DropDownNavItems from './DropDownNavItems';
import NavWithoutSubLinks from './NavWithoutSubLinks';
import NavWithSubLinks from './NavWithSubLinks';

const DrawerNavItems = () => {
  const { handleDrawerToggle, navLinks } = useContext(DataContext);

  const [showSubLinks, setShowSubLinks] = useState('');

  const navComponent = navLinks.map((navLink) => {
    if (navLink.key === 'company') {
      return;
    }

    const newIcon = FanGoIconGenerator({ iconName: navLink.icon });
    let newNavSublinks = null;

    if (navLink.navSubLinks) {
      newNavSublinks = navLink.navSubLinks.map((navSubLink) => {
        const newSubIcon = FanGoIconGenerator({ iconName: navSubLink.icon });

        return { ...navSubLink, icon: newSubIcon };
      });
    }

    return { ...navLink, icon: newIcon, navSubLinks: newNavSublinks };
  });

  const handleClick = (event, key) => {
    setShowSubLinks((prev) => {
      if (prev === key) {
        return '';
      }
      return key;
    });

    const foundLink = navComponent.find((navLink) => navLink.key === key);

    if (!foundLink.navSubLinks) {
      handleDrawerToggle();
    }
  };

  return (
    <List>
      {navComponent.map((navLink) => {
        if (!navLink) {
          return;
        }

        return (
          <React.Fragment key={navLink.key}>
            <ListItem
              disablePadding
              onClick={(event) => {
                handleClick(event, navLink.key);
              }}
              sx={{ cursor: 'pointer' }}
            >
              {navLink.navSubLinks && (
                <NavWithSubLinks
                  navLink={navLink}
                  showSubLinks={showSubLinks}
                />
              )}

              {!navLink.navSubLinks && <NavWithoutSubLinks navLink={navLink} />}
            </ListItem>

            {navLink.navSubLinks && showSubLinks === navLink.key ? (
              <DropDownNavItems navLink={navLink} />
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default DrawerNavItems;
