import React, { useContext } from 'react';

import { OndemandVideo } from '@mui/icons-material';
import { Box } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import NavBarButton from '../../button/NavBarButton';

const AdditionalButtonsBar = () => {
  const {
    pathname,
    homeDirectories,
    handleDrawerToggle,
    mobileOpen,
    setShowDemoDialog,
  } = useContext(DataContext);

  const isHomepage = homeDirectories.includes(pathname);
  const isContactPage = pathname.includes('contactus');
  const isFAQsPage = pathname.includes('faqs');

  const christmasPageButtonColor = pathname.includes('christmas')
    ? 'christmas'
    : null;

  const handleScrollClick = () => {
    const element = document.getElementById('form');

    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleButtonClick = () => {
    setShowDemoDialog(true);

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const buttons = [
    {
      id: 'contactUs',
      label: 'Contact Us',
      styles: { backgroundColor: christmasPageButtonColor },
      onClick: handleScrollClick,
      hrefKey: !isHomepage && !isContactPage && !isFAQsPage ? 'contactus' : '',
      startIcon: null,
    },
    {
      id: 'demoRequest',
      label: 'Request a Demo',
      styles: { backgroundColor: 'neutral', variant: 'outlined' },
      startIcon: null,
      onClick: handleButtonClick,
    },
  ];

  return (
    <Box sx={{ minWidth: '400px', display: { xs: 'none', sm: 'flex' } }}>
      {buttons.map((buttonObj) => {
        const { id, label, styles, startIcon, onClick, hrefKey } = buttonObj;
        const { backgroundColor = '', variant = '' } = styles;
        return (
          <Box
            key={id}
            sx={{
              marginLeft: 3,
              width: '150px',
            }}
          >
            <NavBarButton
              buttonName={label}
              backgroundColor={backgroundColor}
              variant={variant}
              startIcon={startIcon}
              onClick={onClick}
              hrefKey={hrefKey}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default AdditionalButtonsBar;
