import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';

const Animation = ({ children, yValue = 30, delay = 0, stagger = 0.1 }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: [0, 0.1],
    //threshold: 0.1,
  });

  const variants = {
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        delay: i * stagger + delay,
        ease: [0.6, -0.05, 0.01, 0.9],
      },
    }),
    hidden: { opacity: 0, y: yValue },
  };

  return (
    <motion.div
      ref={ref}
      initial={isMobile ? 'visible' : 'hidden'} // Set initial state to "visible" on mobile devices
      variants={variants}
      animate={inView || isMobile ? 'visible' : 'hidden'} // Disable animation if on a mobile device
    >
      {React.Children.map(children, (child, i) => (
        <motion.div custom={i} variants={variants} key={i}>
          {child}
        </motion.div>
      ))}
    </motion.div>
  );
};

export default Animation;
