import React from 'react';

import { Cancel } from '@mui/icons-material';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';

import styles from '../../common/styling/styles';

const FanGoDialog = ({
  open,
  onClose,
  children,
  fullWidth = false,
  maxWidth = null,
  backgroundColor = null,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth || undefined}
      PaperProps={{
        style: {
          backgroundColor: backgroundColor || undefined,
          boxShadow: 'none',
          width: '100%',
          margin: '12px',
        },
      }}
      disableScrollLock
    >
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}></Box>
          <IconButton
            onClick={onClose}
            sx={{ alignItems: 'center', color: styles.deepGreen }}
          >
            <Cancel />
          </IconButton>
        </Box>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default FanGoDialog;
