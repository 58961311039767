import { Box } from "@mui/material";
import React, { useContext } from "react";
import FooterSubText from "../../texts/FooterSubText";
import SocialIcons from "../socials/SocialIcons";
import Logo from "../../logo/Logo";
import { DataContext } from "../../../../providers/DataProvider";

const brandTextStyle = { color: "grey.900", my: 3, mr: { lg: 2 } };

const containerStyle = { my: { xs: 5, md: 0 } };

const LogoSideComp = ({ display = undefined }) => {
  const { footerMessage } = useContext(DataContext);

  return (
    <Box sx={{ ...containerStyle, display: display }}>
      <Logo />

      <Box sx={brandTextStyle}>
        <FooterSubText value={footerMessage} linked={false} />
      </Box>

      <SocialIcons />
    </Box>
  );
};

export default LogoSideComp;
