import React from 'react';

import { Button } from '@mui/material';

import { getLink } from '../../../utils/getLink';

const NavBarButton = ({
  buttonName,
  backgroundColor = null,
  variant = null,
  startIcon = null,
  onClick,
  hrefKey = '',
  paddingTopBottom = '8px',
  paddingLeftRight = '22px',
  fontSize = '13px',
  maxWidth = '100%',
}) => {
  return (
    <Button
      href={getLink({ key: hrefKey })}
      variant={variant || 'contained'}
      color={backgroundColor || 'secondary'}
      onClick={onClick}
      sx={{
        fontSize: fontSize,
        textTransform: 'none',
        paddingTop: paddingTopBottom,
        paddingBottom: paddingTopBottom,
        paddingLeft: paddingLeftRight,
        paddingRight: paddingLeftRight,
        width: '100%',
        maxWidth: maxWidth,
      }}
      startIcon={startIcon || undefined}
    >
      {buttonName}
    </Button>
  );
};

export default NavBarButton;
