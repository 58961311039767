import React, { useContext } from 'react';

import { Box, Link } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import { getLink } from '../../../../utils/getLink';
import LanguagePopover from '../../header/language/LanguagePopover';
import { FooterMainText, FooterSubText } from '../../texts';

const containerStyle = { mb: 2 };

const Company = () => {
  const { setShowDemoDialog, navLinks, languages } = useContext(DataContext);

  const foundNavs = navLinks.find((navLink) => navLink.key === 'company');

  return (
    <Box>
      <FooterMainText value={foundNavs.label} />

      <Box>
        {foundNavs.navSubLinks.map((resource, index) => {
          if (resource.key === 'bookdemo') {
            return (
              <Box
                sx={{
                  ...containerStyle,
                  cursor: 'pointer',
                }}
                key={index}
              >
                <FooterSubText
                  value={resource.label}
                  onClick={() => {
                    setShowDemoDialog(true);
                  }}
                />
              </Box>
            );
          }

          return (
            <Box sx={containerStyle} key={index}>
              <Link
                key={index}
                href={getLink({ key: resource.key })}
                underline="none"
              >
                <FooterSubText value={resource.label} />
              </Link>
            </Box>
          );
        })}
      </Box>

      <Box sx={{ mt: 4 }}>
        {languages.length > 1 && <FooterMainText value="Select a language" />}
        <LanguagePopover mobile />
      </Box>
    </Box>
  );
};

export default Company;
