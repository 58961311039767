import React from 'react';

import { Box, Divider } from '@mui/material';

const Separator = ({ height = 200, pb = null }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        height: height,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pb: pb,
      }}
    >
      <Divider
        sx={{
          width: '100%',
          maxWidth: 1200,
          m: 'auto',
        }}
      />
    </Box>
  );
};

export default Separator;
