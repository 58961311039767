import React, { useContext } from 'react';

import { Box } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';
import Animation from '../../PageComps/CommonComps/animation/Animation';
import styles from '../styling/styles';
import BodyBackground from './background/BodyBackground';

const Body = ({
  backgroundImage = null,
  backgroundColor = styles.white,
  paddingTop = styles.allZero,
  outsidePaddingTop = null,
  paddingBottom = styles.allZero,
  paddingX = styles.allZero,
  paddingY = styles.allFifteen,
  marginBottom = null,
  marginY = styles.allZero,
  minHeight = null,
  display = null,
  justifyContent = null,
  alignItems = null,
  children,
  addPadding = false,
  noAnimation = false,
  position = null,
}) => {
  const paddingStyleforNoBG = { xs: 3, sm: 7, md: 1, lg: 0 };

  const content = (
    <Box
      sx={{
        flexWrap: 'wrap',
        maxWidth: 1200,
        width: '100%',
        minHeight: minHeight || '100%',
        margin: 'auto',
        pt: outsidePaddingTop || undefined,
        px:
          backgroundImage === null || addPadding
            ? paddingStyleforNoBG
            : undefined,
        display: display || undefined,
        justifyContent: justifyContent || undefined,
        alignItems: alignItems || undefined,
        position: position,
      }}
    >
      <Box
        sx={{
          px: paddingX,
          pt: paddingTop,
          pb: paddingBottom,
          py: paddingY,
          mb: marginBottom || undefined,
          color: backgroundColor,
        }}
      >
        {!noAnimation && <Animation>{children}</Animation>}
        {noAnimation && children}
      </Box>
    </Box>
  );

  if (backgroundImage) {
    return (
      <BodyBackground
        bgImage={backgroundImage}
        bgColor={backgroundColor}
        content={content}
        marginY={marginY}
      />
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      {content}
    </Box>
  );
};

export default Body;
