import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import { Box, CssBaseline, GlobalStyles } from '@mui/material';

import Background from '../PageComps/HomePageComps/Background';
import DemoFormDialog from '../PageComps/HomePageComps/DemoFormDialog';
import ExplainerVideoDialog from '../PageComps/HomePageComps/ExplainerVideoDialog';
import { renderPageContent } from './utils/renderPageContent';

const Layout = ({ location, children }) => {
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // useEffect(() => {
  //   const consent = window.CookieConsent.consent;

  //   const marketingConsent = consent.marketing;

  //   if (marketingConsent) {
  //     hotjar.initialize(3485863, 6);

  //     // Identify the user
  //     hotjar.identify("USER_ID", { userProperty: "value" });

  //     // Add an event
  //     hotjar.event("button-click");

  //     // Update SPA state
  //     hotjar.stateChange("/my/page");

  //     // Check if Hotjar has been initialized before calling its methods
  //     if (hotjar.initialized()) {
  //       hotjar.identify("USER_ID", { userProperty: "value" });
  //     }
  //   }
  // }, [window.CookieConsent.consent]);

  return (
    <>
      <GlobalStyles
        styles={{
          ul: { margin: 0, padding: 0, listStyle: 'none' },
        }}
      />

      <Background />

      <Box position="relative">
        <DemoFormDialog />

        <ExplainerVideoDialog />

        <Box sx={{ width: '100%', maxWidth: '100%' }}>
          <CssBaseline />

          {renderPageContent({ pathname, children })}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
