import React from "react";
import { Box } from "@mui/material";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import { getLink } from "../../../utils/getLink";

const Logo = ({
  width = 80,
  height = "100%",
  paddingTop = 0,
  center = false,
}) => {
  const data = useStaticQuery(graphql`
    query Header {
      fanGoLogoImage: file(name: { in: "logo" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 100)
        }
      }
    }
  `);

  const altText = "FanGo Logo";

  const image = getImage(data.fanGoLogoImage);

  return (
    <Link to={getLink({ key: "home" })}>
      <Box
        sx={{
          height: height,
          width: width,
          paddingTop: paddingTop,
          margin: center ? "auto" : undefined,
        }}
      >
        <GatsbyImage image={image} alt={altText} />
      </Box>
    </Link>
  );
};

export default Logo;
