import React, { useContext } from 'react';

import { useScrollTrigger } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';
import FanGoAppBar from './appBar/FanGoAppBar';
import LanguagePopover from './language/LanguagePopover';
import SideBar from './sideBar/SideBar';

const Header = ({ window = null }) => {
  const { pathname } = useContext(DataContext);

  const trigger = useScrollTrigger({
    threshold: 2,
    disableHysteresis: true,
  });

  const persistentAppbarPages = [
    'cookies',
    'customertcs',
    'privacynotice',
    'contactsuccess',
    'blog',
  ];

  const includesAny = persistentAppbarPages.some((page) =>
    pathname.includes(page)
  );

  const showAppBar = () => {
    if (includesAny) {
      return true;
    }

    if (!trigger) {
      return false;
    }

    return true;
  };

  const appBarStyle = showAppBar();

  return (
    <>
      <LanguagePopover />

      <FanGoAppBar />

      {appBarStyle && <FanGoAppBar colored />}

      <SideBar window={window} />
    </>
  );
};

export default Header;
