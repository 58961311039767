import React, { createContext, useState } from 'react';

const initialContext = {
  screenStack: [],
  setScreenStack: null,
  screen: {},
  setScreen: null,
  navigationType: null,
  setNavigationType: null,
  animationPlayStatus: null,
  setAnimationPlayStatus: null,
};

type navType = 'forward' | 'backwards';

export const MobileScreenContext = createContext(initialContext);
const MobileScreenDataProvider: React.FC = ({ children }) => {
  const [screen, setScreen] = useState({});
  const [animationPlayStatus, setAnimationPlayStatus] = useState('running');
  const [screenStack, setScreenStack] = useState([]);
  const [navigationType, setNavigationType] = useState<navType>(null);
  return (
    <MobileScreenContext.Provider
      value={{
        screenStack,
        setScreenStack,
        screen,
        setScreen,
        navigationType,
        setNavigationType,
        animationPlayStatus,
        setAnimationPlayStatus,
      }}
    >
      {children}
    </MobileScreenContext.Provider>
  );
};

export default MobileScreenDataProvider;
