import React from 'react';

// Ticketing Icons
import {
    BookOnline, CallSplit, Celebration, CropFree, DynamicFeed, GridView, ManageAccounts, Map,
    SecurityUpdateGood, ShoppingBasket, SocialDistance, Stars
} from '@mui/icons-material';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
// Ticketing Page
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BookIcon from '@mui/icons-material/Book';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// Software Features Icons
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'; // car parking
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
// Event Offering Icons
import HandshakeIcon from '@mui/icons-material/Handshake';
// Nav links
import HomeIcon from '@mui/icons-material/Home';
// Pricing Page
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import IceSkatingIcon from '@mui/icons-material/IceSkating'; // ice skating
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoreIcon from '@mui/icons-material/More';
// Platform Features Icons
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ParkIcon from '@mui/icons-material/Park';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QuizIcon from '@mui/icons-material/Quiz';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RoomServiceIcon from '@mui/icons-material/RoomService'; // room service
import ShieldIcon from '@mui/icons-material/Shield';
import SickIcon from '@mui/icons-material/Sick';
import StadiumIcon from '@mui/icons-material/Stadium';
import StarsIcon from '@mui/icons-material/Stars';
import StartIcon from '@mui/icons-material/Start';
import TableBarIcon from '@mui/icons-material/TableBar';
// Wedding Feature Icons
import VerifiedIcon from '@mui/icons-material/Verified';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

import styles from '../styling/styles';

const FanGoIconGenerator = ({
  iconName,
  fontSize = 50,
  color = undefined,
  style = {},
  height = '100%',
}) => {
  let icon = undefined;

  const iconStyle = {
    fontSize: fontSize,
    height,
    width: '100%',
    textAlign: 'center',
  };

  const ticketingIconStyle = {
    backgroundColor: styles.lightGreen,
    color: styles.deepGreen,
    fontSize: 100,
    mt: 15,
    mb: 3,
    p: 2,
    borderRadius: '8px',
  };

  switch (iconName) {
    // Pricing Page
    case 'ticketSalesIncrease':
      icon = <AutoGraphIcon sx={iconStyle} />;
      break;
    case 'outsideRegularHours':
      icon = <HomeWorkIcon sx={iconStyle} />;
      break;
    case 'usersServed':
      icon = <LocalActivityIcon sx={iconStyle} />;
      break;
    case 'RoomServiceIcon':
      icon = <RoomServiceIcon sx={iconStyle} />;
      break;

    case 'IceSkatingIcon':
      icon = <IceSkatingIcon sx={iconStyle} />;
      break;

    case 'DirectionsCarIcon':
      icon = <DirectionsCarIcon sx={iconStyle} />;
      break;

    case 'DashboardCustomizeIcon':
      icon = <DashboardCustomizeIcon sx={iconStyle} />;
      break;
    case 'PaymentsIcon':
      icon = <PaymentsIcon sx={iconStyle} />;
      break;
    case 'MeetingRoomIcon':
      icon = <MeetingRoomIcon sx={iconStyle} />;
      break;
    case 'BookOnlineIcon':
      icon = <BookOnlineIcon sx={iconStyle} />;
      break;
    case 'ParkIcon':
      icon = <ParkIcon sx={iconStyle} />;
      break;
    case 'CelebrationIcon':
      icon = <CelebrationIcon sx={iconStyle} />;
      break;
    case 'WeddingIcon':
      icon = <FavoriteIcon sx={iconStyle} />;
      break;
    case 'MenuBookIcon':
      icon = <MenuBookIcon sx={iconStyle} />;
      break;
    case 'EventNoteIcon':
      icon = <EventNoteIcon sx={iconStyle} />;
      break;
    case 'TableBarIcon':
      icon = <TableBarIcon sx={iconStyle} />;
      break;
    case 'RestaurantMenuIcon':
      icon = <RestaurantMenuIcon sx={iconStyle} />;
      break;
    case 'Verified':
      icon = <VerifiedIcon sx={iconStyle} />;
      break;
    case 'AssignmentIcon':
      icon = <AssignmentIcon sx={iconStyle} />;
      break;

    case 'HandshakeIcon':
      icon = <HandshakeIcon sx={iconStyle} />;
      break;
    case 'Diversity3Icon':
      icon = <Diversity3Icon sx={iconStyle} />;
      break;
    case 'ManageAccountsIcon':
      icon = <ManageAccountsIcon sx={iconStyle} />;
      break;
    case 'RocketLaunchIcon':
      icon = <RocketLaunchIcon sx={iconStyle} />;
      break;

    case 'NoteAddIcon':
      icon = <NoteAddIcon sx={iconStyle} />;
      break;
    case 'ShieldIcon':
      icon = <ShieldIcon sx={iconStyle} />;
      break;
    case 'CallSplitIcon':
      icon = <CallSplitIcon sx={iconStyle} />;
      break;
    case 'ReceiptIcon':
      icon = <ReceiptIcon sx={iconStyle} />;
      break;
    case 'EventSeatIcon':
      icon = <EventSeatIcon sx={iconStyle} />;
      break;
    case 'SickIcon':
      icon = <SickIcon sx={iconStyle} />;
      break;
    case 'AutoFixHighIcon':
      icon = <AutoFixHighIcon sx={iconStyle} />;
      break;
    case 'ViewCarouselIcon':
      icon = <ViewCarouselIcon sx={iconStyle} />;
      break;
    case 'AddAlertIcon':
      icon = <AddAlertIcon sx={iconStyle} />;
      break;
    case 'QrCodeScannerIcon':
      icon = <QrCodeScannerIcon sx={iconStyle} />;
      break;
    case 'AssessmentIcon':
      icon = <AssessmentIcon sx={iconStyle} />;
      break;
    case 'LoyaltyIcon':
      icon = <LoyaltyIcon sx={iconStyle} />;
      break;
    case 'PublishedWithChangesIcon':
      icon = <PublishedWithChangesIcon sx={iconStyle} />;
      break;
    case 'BrunchDiningIcon':
      icon = <BrunchDiningIcon sx={iconStyle} />;
      break;
    case 'MoreIcon':
      icon = <MoreIcon sx={iconStyle} />;
      break;

    case 'BookOnline':
      icon = <BookOnline sx={ticketingIconStyle} />;
      break;
    case 'CropFree':
      icon = <CropFree sx={ticketingIconStyle} />;
      break;
    case 'DynamicFeed':
      icon = <DynamicFeed sx={ticketingIconStyle} />;
      break;
    case 'GridView':
      icon = <GridView sx={ticketingIconStyle} />;
      break;
    case 'ManageAccounts':
      icon = <ManageAccounts sx={ticketingIconStyle} />;
      break;
    case 'Map':
      icon = <Map sx={ticketingIconStyle} />;
      break;
    case 'SecurityUpdateGood':
      icon = <SecurityUpdateGood sx={ticketingIconStyle} />;
      break;
    case 'ShoppingBasket':
      icon = <ShoppingBasket sx={ticketingIconStyle} />;
      break;
    case 'SocialDistance':
      icon = <SocialDistance sx={ticketingIconStyle} />;
      break;
    case 'Stars':
      icon = <Stars sx={ticketingIconStyle} />;
      break;
    case 'GroupsIcon':
      icon = <GroupsIcon />;
      break;

    case 'HomeIcon':
      icon = <HomeIcon />;
      break;
    case 'StartIcon':
      icon = <StartIcon />;
      break;
    case 'PeopleAltIcon':
      icon = <PeopleAltIcon />;
      break;
    case 'AddShoppingCartIcon':
      icon = <AddShoppingCartIcon />;
      break;
    case 'PaymentIcon':
      icon = <PaymentIcon />;
      break;
    case 'QuizIcon':
      icon = <QuizIcon />;
      break;
    case 'ContactSupportIcon':
      icon = <ContactSupportIcon />;
      break;
    case 'WysiwygIcon':
      icon = <WysiwygIcon />;
      break;
    case 'ConfirmationNumberIcon':
      icon = <ConfirmationNumberIcon />;
      break;
    case 'StadiumIcon':
      icon = <StadiumIcon />;
      break;
    case 'WorkspacesIcon':
      icon = <WorkspacesIcon />;
      break;
    case 'WorkHistoryIcon':
      icon = <WorkHistoryIcon />;
      break;
    case 'IntegrationInstructionsIcon':
      icon = <IntegrationInstructionsIcon />;
      break;
    case 'BookIcon':
      icon = <BookIcon />;
      break;

    case 'AutoGraphIcon':
      icon = <AutoGraphIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'PendingActionsIcon':
      icon = <PendingActionsIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'StarsIcon':
      icon = <StarsIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'LibraryAddIcon':
      icon = <LibraryAddIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'HowToRegIcon':
      icon = <HowToRegIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'GroupWorkIcon':
      icon = <GroupWorkIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'CalendarViewMonthIcon':
      icon = <CalendarViewMonthIcon sx={{ fontSize, color, ...style }} />;
      break;
    case 'CallSplit':
      icon = <CallSplit sx={{ fontSize, color, ...style }} />;
      break;
    case 'Celebration':
      icon = <Celebration sx={{ fontSize, color, ...style }} />;
      break;

    default:
      console.log("This case doesn't exist");
  }

  return icon;
};

export default FanGoIconGenerator;
