import React, { useContext } from 'react';
import FanGoDialog from '../CommonComps/FanGoDialog';
import VideoPlayer from '../PreOrderPageComps/VideoPlayer';
import { DataContext } from '../../../providers/DataProvider';
import { graphql, useStaticQuery } from 'gatsby';

const ExplainerVideoDialog = () => {
  const queryData = useStaticQuery(graphql`
    query {
      sanityHomePage {
        pageHead {
          videoID
        }
      }
    }
  `);
  const { sanityHomePage } = queryData;

  const { pageHead } = sanityHomePage;

  const { videoID } = pageHead;

  const { showExplainerVideo, setShowExplainerVideo } = useContext(DataContext);

  return (
    <FanGoDialog
      onClose={() => {
        setShowExplainerVideo(false);
      }}
      open={showExplainerVideo}
      fullWidth={true}
      maxWidth="md"
      backgroundColor="transparent"
    >
      {videoID && <VideoPlayer videoID={videoID} marginTop={0} />}
    </FanGoDialog>
  );
};

export default ExplainerVideoDialog;
