import React, { useContext, useEffect, useState } from "react";
import {
  MenuItem,
  Stack,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { Link } from "gatsby-plugin-react-i18next";
import Flag from "./utils/Flag";
import { DataContext } from "../../../../providers/DataProvider";
import { isoLangs } from "./utils/isoLangs";
import styles from "../../styling/styles";

const LanguagePopover = ({
  mobile = false,
  fontColor = "white",
  center = false,
}) => {
  const { languages, currentLanguage, originalPath } = useContext(DataContext);

  if (languages.length < 2) {
    return null;
  }

  const LANGS = languages.map((lang) => ({
    value: lang,
    label: isoLangs[lang]?.name,
  }));

  const StartingLang = LANGS.find((lang) => lang.value === currentLanguage);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleClose, true);
    return () => {
      document.removeEventListener("scroll", handleClose, true);
    };
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={
          !mobile
            ? {
                padding: 0,
                top: 0,
                mt: 2.7,
                position: "fixed",
                right: 20,
                zIndex: 1500,
                display: { xs: "none", lg: "block" },
              }
            : {
                display: "flex",
                margin: center ? "auto" : undefined,
                borderRadius: 0,
                padding: 0,
              }
        }
      >
        {mobile && (
          <>
            <Flag code={StartingLang.value} />
            <Typography
              variant="subtitle2"
              sx={{ fontSize: 16, color: fontColor }}
            >
              {StartingLang.label}
            </Typography>
          </>
        )}

        {!mobile && <Flag code={StartingLang.value} />}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        disablePortal
        disableScrollLock
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 150,
            "& .MuiMenuItem-root": {
              px: 1,
              fontSize: 15,
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => {
            const { value, label } = option;

            return (
              <Link
                key={value}
                to={originalPath}
                language={value}
                style={{ textDecoration: "none" }}
                onClick={() => handleClose()}
              >
                <MenuItem
                  selected={value === currentLanguage}
                  sx={{ color: styles.grey }}
                  divider
                >
                  <Flag code={value} height={mobile ? 15 : 20} />
                  {label}
                </MenuItem>
              </Link>
            );
          })}
        </Stack>
      </Popover>
    </>
  );
};

export default LanguagePopover;
