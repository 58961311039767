import React from "react";
import { Box } from "@mui/material";
import { Script } from "gatsby";

const TrustPilotWidget = () => {
  return (
    <Box sx={{ my: 4 }}>
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      <div
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="634d32239e465487b5c4baed"
        data-style-height="24px"
        data-style-width="100%"
        data-theme="dark"
        data-min-review-count="10"
        data-without-reviews-preferred-string-id="1"
        data-style-alignment="center"
      >
        <a
          href="https://uk.trustpilot.com/review/fangocloud.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </Box>
  );
};

export default TrustPilotWidget;
