import {
  bindHover,
  bindMenu,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import React from 'react';

import { Box } from '@mui/material';

import DropDownMenu from './DropDownMenu';
import TopLevelMenu from './TopLevelMenu';

const FanGoMenuItem = ({ navLink }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  });

  return (
    <Box sx={{ height: '67px' }}>
      <Box
        sx={{
          height: '100%',
        }}
      >
        <TopLevelMenu
          navLink={navLink}
          bindHover={bindHover}
          popupState={popupState}
        />
      </Box>

      {navLink.navSubLinks ? (
        <DropDownMenu
          navSubLinks={navLink.navSubLinks}
          HoverMenu={HoverMenu}
          bindMenu={bindMenu}
          popupState={popupState}
        />
      ) : null}
    </Box>
  );
};

export default FanGoMenuItem;
