import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';

import { getBackgroundImageGatsbyData } from '../components/componentUtils';

const initialContext = {
  showDemoDialog: null,
  setShowDemoDialog: null,
  showExplainerVideo: null,
  setShowExplainerVideo: null,
  blogCategories: null,
  pathname: null,
  mobileOpen: null,
  setMobileOpen: null,
  handleDrawerToggle: null,
  pageContext: {
    language: 'en',
    i18n: { path: '' },
  },
  navLinks: [],
  footerMessage: '',
  copyrightMessage: '',
  navGroupName: '',
  navButtons: { contact: '', demo: '', planYourEvent: '' },
  homeDirectories: [],
  languages: [],
  hydrated: null,
  currentLanguage: '',
  originalPath: '',
  activeCategory: '',
  setActiveCategory: null,
  currentScrollSection: 'Event Planning Checklist',
  setCurrentScrollSection: null,
  contentLoading: true,
  setContentLoading: null,
  heroSectionBackgroundImage: null,
};

export const DataContext = createContext(initialContext);

const DataProvider = ({ children, pageContext, location }) => {
  const data = useStaticQuery(graphql`
    query {
      allLocale {
        nodes {
          data
          language
        }
      }
      sanityCommonContent(language: { eq: "en" }) {
        backgrounds {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
          }
        }
      }
    }
  `);

  const { allLocale } = data;

  const { pathname } = location;

  const { i18n = {} } = pageContext;

  const { languages, language: currentLanguage, originalPath } = i18n;

  const blogCategories = new Set(['All']);

  const foundBackgroundImage = getBackgroundImageGatsbyData({
    imageName: 'backgroundImage',
    sanityData: data,
  });

  const [showDemoDialog, setShowDemoDialog] = useState(false);
  const [showExplainerVideo, setShowExplainerVideo] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hydrated, setHydrated] = useState(false);
  const [activeCategory, setActiveCategory] = useState('All');
  const [currentScrollSection, setCurrentScrollSection] = useState(
    'Event Planning Checklist'
  );
  const [contentLoading, setContentLoading] = useState(true);

  useEffect(() => {
    setHydrated(true);
  }, []);

  const { nodes } = allLocale;

  const foundNode = nodes.find(
    (node) => node.language === pageContext.language
  );

  const languageData = JSON.parse(foundNode.data);

  const {
    navLinks,
    footerMessage,
    navGroupName,
    navButtons,
    copyrightMessage,
  } = languageData;

  const homeDirectories = languages.map((language) => {
    if (language === 'en') {
      return '/';
    }

    return `/${language}/`;
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <DataContext.Provider
      value={{
        contentLoading,
        setContentLoading,
        currentScrollSection,
        setCurrentScrollSection,
        showDemoDialog,
        setShowDemoDialog,
        showExplainerVideo,
        setShowExplainerVideo,
        blogCategories,
        pathname,
        mobileOpen,
        setMobileOpen,
        handleDrawerToggle,
        pageContext,
        navLinks,
        footerMessage,
        copyrightMessage,
        navGroupName,
        navButtons,
        homeDirectories,
        languages,
        hydrated,
        currentLanguage,
        originalPath,
        activeCategory,
        setActiveCategory,
        heroSectionBackgroundImage: foundBackgroundImage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
