import axios from "axios";

interface GetData {
  type: string;
  apiVersion?: string;
}

export async function getData({ type, apiVersion = "2021-10-21" }: GetData) {
  const endPoint = `https://nez2sw4h.api.sanity.io/v${apiVersion}/data/query/production?query=*%5B_type%20%3D%3D%20%22${type}%22%5D`;

  let data = [];

  try {
    const response = await axios.get(endPoint);

    data = response.data.result;
  } catch (error) {
    console.log("Error:", error);
  }

  return data;
}
