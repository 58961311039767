import React from 'react';

import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import Separator from '../../common/styling/Separator';

export const renderPageContent = ({ pathname, children }) => {
  if (pathname.includes('404')) {
    return <>{children}</>;
  }

  return (
    <>
      <Header />
      {children}
      <Separator height={50} />
      <Footer />
    </>
  );
};
