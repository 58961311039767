import { Box, Icon, Typography } from "@mui/material";
import { Link } from "gatsby";
import React from "react";
import { sideBarActive, button } from "../header.module.css";
import { getLink } from "../../../../utils/getLink";

const iconPlusNameContainerStyle = {
  display: "flex",
  direction: "row",
  alignItems: "center",
};

const NavWithoutSubLinks = ({ navLink }) => {
  return (
    <Link
      to={getLink({ key: navLink.key })}
      style={{
        width: "100%",
      }}
      className={button}
      activeClassName={sideBarActive}
    >
      <Box sx={iconPlusNameContainerStyle}>
        <Box>
          <Icon children={navLink.icon} />
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {navLink.label}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default NavWithoutSubLinks;
