import { Typography } from '@mui/material';
import React from 'react';

const FooterMainText = ({ value }) => {
  return (
    <Typography
      variant="subtitle2"
      mb={2}
      sx={{
        color: 'common.white',
        fontWeight: 700,
      }}
      children={value}
    />
  );
};

export default FooterMainText;
