import { Typography } from '@mui/material';
import React from 'react';
import styles from '../styling/styles';

const SubText = ({
  fontColor = styles.white,
  fontWeight = null,
  variant,
  textAlignment = styles.alignStyleCCCC,
  marginY = styles.allFour,
  marginTop = null,
  marginBottom = null,
  marginX = styles.allZero,
  paddingX = styles.allZero,
  paddingTop = null,
  ml = styles.allZero,
  strikeThrough = false,
  value,
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        color: fontColor,
        my: marginY,
        mx: marginX,
        textAlign: textAlignment,
        ml: ml,
        fontWeight: fontWeight || undefined,
        px: paddingX,
        mt: marginTop || undefined,
        mb: marginBottom || undefined,
        pt: paddingTop || undefined,
        wordWrap: 'break-word',
        textOverflow: 'ellipsis',
        textDecoration: strikeThrough ? 'line-through' : 'none',
      }}
      children={value}
    />
  );
};

export default SubText;
