import * as React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import customShadow from "./shadows";
import customTypography from "./fonts";
import customPalette from "./palette";
import customBreakPoints from "./breakpoints";

const ThemeProvider = ({ children }) => {
  const theme = createTheme({
    palette: customPalette,
    shadows: customShadow,
    typography: customTypography,
    breakpoints: customBreakPoints,
  });
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
