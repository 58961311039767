import { Link } from 'gatsby';
import React from 'react';

import { Box, Grid, Icon, MenuItem, Typography, useMediaQuery } from '@mui/material';

import { getLink } from '../../../../utils/getLink';
import FanGoIconGenerator from '../../icon/FanGoIconGenerator';
import Sparkles from '../../sparkleComp/Sparkles';
import { active, dot, dropDownText, text } from '../header.module.css';

const menuItemStyle = {
  width: '100%',
  px: 2,
  display: 'flex',
};

const DropDownMenu = ({ navSubLinks, HoverMenu, bindMenu, popupState }) => {
  const largeScreen = useMediaQuery(`(min-width: 1441px)`);
  const xlScreen = useMediaQuery(`(min-width: 1650px)`);

  const NavItem = ({ subLinkIcon, key, label }) => {
    const addSparkles = label === 'Christmas';
    //const addSparkles = false;
    return (
      <div style={{ width: '100%' }} key={key}>
        <Link
          to={getLink({ key: key })}
          style={{
            textDecoration: 'none',
            color: 'lightGrey',
          }}
          activeClassName={active}
          key={key}
        >
          <Box
            sx={{
              py: 1,
            }}
          >
            <MenuItem sx={menuItemStyle} className={text}>
              <Box>
                <Icon children={subLinkIcon} />
              </Box>
              {addSparkles && (
                <Sparkles>
                  <Typography
                    variant="body2"
                    sx={{
                      px: 2,
                      fontWeight: 800,
                    }}
                    className={dropDownText}
                  >
                    {label}
                  </Typography>
                </Sparkles>
              )}
              {!addSparkles && (
                <Typography
                  variant="body2"
                  sx={{
                    px: 2,
                    fontWeight: 800,
                  }}
                  className={dropDownText}
                >
                  {label}
                </Typography>
              )}
            </MenuItem>
          </Box>
        </Link>
      </div>
    );
  };

  return (
    <HoverMenu
      {...bindMenu(popupState)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      disablePortal
      disableScrollLock
      PaperProps={{
        style: {
          borderTop: '3px solid #2D9B83',
          boxShadow: 'none',
          borderRadius: '0px 0px 4px 4px',
          // width: xlScreen ? '25%' : '30%',
          width: '',
        },
      }}
    >
      {navSubLinks.map((navSubLink) => {
        const { icon, label, key } = navSubLink;
        const subLinkIcon = FanGoIconGenerator({
          iconName: icon,
        });
        const Item = NavItem({ subLinkIcon, label, key });
        return Item;
      })}
    </HoverMenu>
  );
};

export default DropDownMenu;
