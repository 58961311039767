import React, { useContext, useEffect, useState } from 'react';

import { Box, Divider } from '@mui/material';

import { DataContext } from '../../../providers/DataProvider';
import { getData } from '../../../utils/getData';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import DemoFormHandler from '../../form/DemoFormHandler';
import FanGoDialog from '../CommonComps/FanGoDialog';

const DemoFormDialog = () => {
  const { pageContext } = useContext(DataContext);

  const [homePage, setHomePage] = useState({
    pageHead: { demoForm: { title: '', subTitle: '' } },
  });

  useEffect(() => {
    (async () => {
      const homePageData = await getData({ type: 'homePage' });

      const foundHomePage = homePageData.find(
        (data) => data.language === pageContext.language
      );

      return setHomePage(foundHomePage);
    })();
  }, [pageContext.language]);

  let howDidYouHearAboutUsOptions = [];
  const { pageHead } = homePage;
  if (pageHead) {
    const { referralOptions } = pageHead;
    if (referralOptions) {
      const { referralOptionsList } = referralOptions;
      howDidYouHearAboutUsOptions = referralOptionsList;
    }
  }

  const demoForm = homePage.pageHead.demoForm;
  const demoFormAndReferralOptions = {
    referralOptions: howDidYouHearAboutUsOptions,
    ...demoForm,
  };

  const { showDemoDialog, setShowDemoDialog } = useContext(DataContext);

  return (
    <FanGoDialog
      onClose={() => {
        setShowDemoDialog(false);
      }}
      open={showDemoDialog}
    >
      <Box sx={{ pl: 3, pr: 3 }}>
        <MainText
          textVariant="h4"
          fontColor={styles.deepGreen}
          textAlignment={styles.alignStyleLLLL}
          paddingX={styles.allZero}
          marginY={styles.allZero}
          value={demoForm.title}
          fontWeight={500}
        />
        <SubText
          variant="body1"
          fontColor="grey.900"
          marginY={styles.allTwo}
          textAlignment={styles.alignStyleLLLL}
          value={demoForm.subTitle}
        />

        <Divider sx={{ backgroundColor: styles.deepGreen }} />

        <DemoFormHandler formData={demoFormAndReferralOptions} />
      </Box>
    </FanGoDialog>
  );
};

export default DemoFormDialog;
