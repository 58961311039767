import React, { useContext } from "react";
import { Box, Drawer } from "@mui/material";
import FangoDrawer from "./FangoDrawer";
import { DataContext } from "../../../../providers/DataProvider";

const drawerWidth = 260;

const SideBar = ({ window }) => {
  const container = !!window ? () => window().document.body : undefined;

  const { handleDrawerToggle, mobileOpen } = useContext(DataContext);

  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        disablePortal
        disableRestoreFocus
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          sx: {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        sx={{
          display: { lg: "none" },
        }}
      >
        <FangoDrawer />
      </Drawer>
    </Box>
  );
};

export default SideBar;
