import { Box, Toolbar } from "@mui/material";
import React from "react";
import Logo from "../../logo/Logo";
import LargeScreenConfig from "../screenConfigs/LargeScreenConfig";
import SmallScreenConfig from "../screenConfigs/SmallScreenConfig";

const toolBarStyle = {
  px: { xs: 3, sm: 7, md: 1, lg: 0 },
  height: "100%",
  "&.MuiToolbar-root": {
    minHeight: "0px",
  },
};

const ToolBarContent = () => {
  return (
    <Toolbar disableGutters={true} sx={toolBarStyle}>
      <Logo width={70} />

      <LargeScreenConfig />

      <SmallScreenConfig />
    </Toolbar>
  );
};

export default ToolBarContent;
