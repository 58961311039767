import React from "react";
import Flags from "country-flag-icons/react/3x2";

const Flag = ({ code, height = 20 }) => {
  let Flag = Flags["GB"];

  const updatedCode = code.toUpperCase();

  if (updatedCode !== "EN") {
    Flag = Flags[updatedCode] || Flags["GB"];
  }

  return <Flag style={{ height, marginRight: 8 }} />;
};

export default Flag;
