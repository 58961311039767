import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../providers/DataProvider";

const copyrightContainerStyle = {
  width: "50%",
  margin: "auto",
  textAlign: "center",
};

const CopyrightText = () => {
  const { hydrated, copyrightMessage } = useContext(DataContext);

  if (!hydrated) {
    return null;
  }

  return (
    <Box sx={copyrightContainerStyle}>
      <Typography variant="caption">
        &copy; {new Date().getFullYear()}
        <span> {copyrightMessage} </span>
      </Typography>
    </Box>
  );
};

export default CopyrightText;
