import { Link } from 'gatsby';
import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Typography } from '@mui/material';

import { getLink } from '../../../../utils/getLink';
import { active, dot, text } from '../header.module.css';

const TopLevelMenu = ({ navLink, bindHover, popupState }) => {
  if (navLink.navSubLinks) {
    return (
      <MenuItem
        {...bindHover(popupState)}
        sx={{
          zIndex: 1301,
          height: '100%',
        }}
      >
        <Typography
          variant="body2"
          className={text}
          sx={{ fontWeight: 800 }}
          align="center"
        >
          {navLink.label}
        </Typography>
        <KeyboardArrowDownIcon
          className={text}
          sx={{
            transform: popupState.isOpen ? 'rotate(180deg)' : null,
          }}
        />
      </MenuItem>
    );
  }

  return (
    <Link
      to={getLink({ key: navLink.key })}
      style={{ textDecoration: 'none' }}
      activeClassName={active}
    >
      <MenuItem
        sx={{
          zIndex: 1301,
          height: '100%',
        }}
      >
        <span className={dot}></span>
        <Typography
          variant="body2"
          className={text}
          sx={{ fontWeight: 800 }}
          align="center"
        >
          {navLink.label}
        </Typography>
      </MenuItem>
    </Link>
  );
};

export default TopLevelMenu;
