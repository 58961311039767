import { Box } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const Background = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        sanityCommonContent(language: { eq: "en" }) {
          _id
          backgrounds {
            altText
            asset {
              url
            }
          }
        }
      }
    `
  );

  const { sanityCommonContent } = data;

  const { backgrounds } = sanityCommonContent;

  const imageData = backgrounds.find(
    (background) => background.altText === "body"
  );

  const bgStyle = {
    position: "fixed",
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-y",
    backgroundSize: "cover",
    backgroundColor: "#0A447C",
    backgroundImage: `url(${imageData?.asset?.url})`,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100vw",
    minHeight: "100vh",
  };

  return <Box sx={bgStyle} />;
};

export default Background;
