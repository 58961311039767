import React, { useContext } from 'react';
import { Box, Link } from '@mui/material';
import { FooterMainText, FooterSubText } from '../../texts';
import { DataContext } from '../../../../providers/DataProvider';
import { getLink } from '../../../../utils/getLink';

const containerStyle = { mb: 2 };

const OurSoftware = () => {
  const { navLinks } = useContext(DataContext);

  const foundNav = navLinks.find((navLink) => navLink.key === 'ourSoftware');

  return (
    <Box>
      <FooterMainText value={foundNav.label} />
      <Box>
        {foundNav.navSubLinks.map((navLink, index) => {
          return (
            <Box key={index} sx={containerStyle}>
              <Link href={getLink({ key: navLink.key })} underline="none">
                <FooterSubText value={navLink.label} />
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default OurSoftware;
