import React from "react";
import { Box } from "@mui/material";
import FooterBody from "./comps/FooterBody";
import Body from "../body/Body";
import styles from "../styling/styles";

const footerBodyStyle = {
  flexWrap: "wrap",
  maxWidth: 1200,
  width: "100%",
  bottom: 0,
  position: "relative",
};

const Footer = () => {
  return (
    <Box position="relative">
      <Body backgroundImage="bottom" paddingY={styles.allSix} noAnimation>
        <Box sx={footerBodyStyle}>
          <FooterBody />
        </Box>
      </Body>
    </Box>
  );
};

export default Footer;
