import * as Sentry from '@sentry/gatsby';

const dynamicPlugins = [];

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://de0cc0e98e424de78aff102c6e1bb1f9@o559860.ingest.sentry.io/4504926713217024',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  dynamicPlugins.push({
    resolve: '@sentry/gatsby',
  });
}

export default dynamicPlugins;
