import { Divider } from "@mui/material";
import React from "react";
import TrustPilotWidget from "../../../PageComps/CommonComps/TrustPilotReviews";
import CopyrightText from "./CopyrightText";
import styles from "../../styling/styles";
import BrandComponent from "./BrandComponent";

const FooterBody = () => {
  return (
    <>
      <BrandComponent />

      <Divider sx={{ mt: 5, backgroundColor: styles.white }} />

      <TrustPilotWidget />

      <CopyrightText />

      <Divider sx={{ mt: 5, backgroundColor: styles.white }} />
    </>
  );
};

export default FooterBody;
