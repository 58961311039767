import { Box } from '@mui/material';
import React from 'react';

const FormCover = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 10,
        margin: 'auto',
      }}
    ></Box>
  );
};

export default FormCover;
