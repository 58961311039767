import { Link } from 'gatsby';
import React, { useContext } from 'react';

import { Box, Icon, List, ListItem, Stack, Typography } from '@mui/material';

import { DataContext } from '../../../../providers/DataProvider';
import { getLink } from '../../../../utils/getLink';
import Sparkles from '../../sparkleComp/Sparkles';
import { button, sideBarActive } from '../header.module.css';

const iconAndNameStyle = {
  display: 'flex',
  direction: 'row',
  alignItems: 'center',
};

const textStyle = { mx: 2 };

const DropDownNavItems = ({ navLink }) => {
  const { handleDrawerToggle } = useContext(DataContext);

  const NavItem = ({ navSubLink }) => {
    const { key, icon, label } = navSubLink;
    const addSparkles = label === 'Christmas';

    return (
      <ListItem key={key} onClick={handleDrawerToggle} disablePadding>
        <Link
          to={getLink({ key: key })}
          style={{
            width: '100%',
            borderRadius: 5,
          }}
          className={button}
          activeClassName={sideBarActive}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            width="100%"
          >
            <Box sx={iconAndNameStyle}>
              <Box>
                <Icon children={icon} />
              </Box>
              <Box sx={textStyle}>
                {addSparkles && (
                  <Sparkles>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {label}
                    </Typography>
                  </Sparkles>
                )}
                {!addSparkles && (
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    {label}
                  </Typography>
                )}
              </Box>
            </Box>
          </Stack>
        </Link>
      </ListItem>
    );
  };

  return (
    <List sx={textStyle}>
      {navLink.navSubLinks.map((navSubLink) => {
        return <NavItem navSubLink={navSubLink} />;
      })}
    </List>
  );
};

export default DropDownNavItems;
