import { Box, Stack, Icon, Typography, ListItemIcon } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import { button } from "../header.module.css";

const iconPlusNameContainerStyle = {
  display: "flex",
  direction: "row",
  alignItems: "center",
};

const NavWithSubLinks = ({ navLink, showSubLinks }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      width="100%"
      sx={{
        width: "100%",
      }}
      className={button}
    >
      <Box sx={iconPlusNameContainerStyle}>
        <Box>
          <Icon children={navLink.icon} />
        </Box>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {navLink.label}
          </Typography>
        </Box>
      </Box>
      <Box>
        <ListItemIcon
          sx={{ minWidth: 0 }}
          children={
            <ArrowForwardIosIcon
              sx={{
                fontSize: "small",
                transform:
                  navLink.navSubLinks && showSubLinks === navLink.key
                    ? "rotate(90deg)"
                    : null,
              }}
            />
          }
        />
      </Box>
    </Stack>
  );
};

export default NavWithSubLinks;
