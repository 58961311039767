import React from 'react';
import Layout from './src/components/layout/Layout';
import ThemeProvider from './src/theme/ThemeProvider';
import DataProvider from './src/providers/DataProvider';
import MobileScreenDataProvider from './src/providers/MobileScreenProvider';

export const wrapPageElement = ({ element, props }) => {
  return (
    <DataProvider {...props}>
      <ThemeProvider>
        <MobileScreenDataProvider>
          <Layout {...props}>{element}</Layout>
        </MobileScreenDataProvider>
      </ThemeProvider>
    </DataProvider>
  );
};
